var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin-dashboard-layout',[_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"grey","to":{name: 'admin.blogs.create'}}},[_vm._v("Create")])],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.blogs,"items-per-page":15},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"cursor-pointer text--blue"},[_c('v-col',{on:{"click":function($event){return _vm.handleClickName(item)}}},[_vm._v(" "+_vm._s(item.title_ru)+" ")])],1)]}},{key:"item.text",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"cursor-pointer text--blue"},[_c('v-col',{on:{"click":function($event){return _vm.handleClickName(item)}}},[_vm._v(" "+_vm._s(item.text_ru)+" ")])],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.created_at).toLocaleString())+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('blog-delete-dialog',{attrs:{"blog":item},on:{"deleted":_vm.handleRemoveBlog}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }