<template>
    <v-dialog
        v-model="dialog"
        width="500"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="red lighten-2"
                dark
                v-bind="attrs"
                v-on="on"
            >
                Delete
            </v-btn>
        </template>

        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                Delete {{blog.title}}
            </v-card-title>

            <v-card-text>
                {{text}}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-btn
                    color="grey"
                    text
                    @click="dialog = false"
                >
                    Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="handleDeleteBlog"
                >
                    Yes
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Blog from "../../models/Blog";

export default {
    name: "BlogDeleteDialog",
    props: ['blog'],
    data: function () {
        return {
            dialog: false,
            text: 'Are you sure?'
        }
    },
    methods: {
        async handleDeleteBlog() {
            let id = this.blog.id
            const blog = new Blog(this.blog)
            let resp = await blog.delete()
            if (resp.status === 200) {
                this.$emit("deleted", id)
                this.dialog = false
            } else {
                this.text = "ERROR"
            }
        }
    },
}
</script>

<style scoped>

</style>
