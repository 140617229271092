<template>
    <admin-dashboard-layout>
        <v-row>
            <v-col class="text-right">
                <v-btn color="grey" :to="{name: 'admin.blogs.create'}">Create</v-btn>
            </v-col>
        </v-row>
        <v-data-table :loading="loading" loading-text="Loading... Please wait" :headers="headers" :items="blogs"
            :items-per-page="15" class="elevation-1">
            <template v-slot:item.title="{ item }">
                <v-row class="cursor-pointer text--blue">
                    <v-col @click="handleClickName(item)">
                        {{ item.title_ru }}
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.text="{ item }">
                <v-row class="cursor-pointer text--blue">
                    <v-col @click="handleClickName(item)">
                        {{ item.text_ru }}
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ new Date(item.created_at).toLocaleString() }}
            </template>
            <template v-slot:item.actions="{ item }">
                <blog-delete-dialog @deleted="handleRemoveBlog" :blog="item" />
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import BlogDeleteDialog from "../../../components/dialogs/BlogDeleteDialog.vue";
import Blog from '../../../models/Blog';


export default {
    name: "blogs-index",
    components: { AdminDashboardLayout, BlogDeleteDialog },
    data: () => (
        {
            loading: false,
            blogs: [],
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                { text: 'Title', value: 'title' },
                { text: 'Text', value: 'text' },
                { text: 'Created at', value: 'created_at' },
                { text: 'Actions', value: 'actions' },
            ],
        }
    ),
    methods: {
        handleClickName(blog) {
            this.$router.push({ name: 'admin.blogs.show', params: { id: blog.id } })
        },
        handleRemoveBlog(id) {
            this.blogs = this.$_.filter(this.blogs, blog => blog.id !== id);
        }
    },
    async mounted() {
        this.loading = true
        this.blogs = await Blog.get()
        this.loading = false;
    },
}
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}

.text--blue {
    color: #0d47a1;
}

.text--blue:hover {
    text-decoration: underline;
}

.max-width150 {
    max-width: 150px;
}
</style>
